import React from "react";
import './Home.css';
import Header from "../Header/Header";
import FooterHeader from "../FooterHeader/FooterHeader";
import img1 from '../../images/banners2.png';
import img2 from '../../images/OPERATIONAL.png';
import img3 from '../../images/growth-icon-0.png';

function Home() {
    return (
        <>
            <Header />
            <div classNameName="container-fluid p-5"
                style={{
                    backgroundImage: `url(${img1})`,
                    backgroundSize: 'cover',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    width: '100%',
                    height: '500px',
                    textAlign: 'center',
                }}>
                <div style={{
                    left: '0',
                    top: '32%',
                    right: '0',
                    position: 'absolute'
                }}>
                    <h3 style={{
                        textTransform: "uppercase", color: '#fff',
                        fontSize: '20px',
                        textShadow: 'none',
                        margin: '0 0 10px 0',
                        fontWeight: 'normal',
                        letterSpacing: '5px',
                        fontFamily: 'Roboto sans-serif'
                    }}>With All Due our Expertise.</h3>
                    <h2 style={{
                        color: '#f9b500', fontSize: '40px',
                        fontWeight: '700',
                        textShadow: 'none',
                        fontStyle: 'italic',
                        margin: '0 0 10px 0',
                        letterSpacing: '1px',
                        textTransform: 'capitalize',

                        fontFamily: 'Lora  serif'
                    }}>Missed Call On +919926146902</h2>
                    <h3 style={{
                        textTransform: "uppercase", color: '#fff',
                        fontSize: '20px',
                        textShadow: 'none',
                        margin: '0 0 10px 0',
                        fontWeight: 'normal',
                        letterSpacing: '5px',
                        fontFamily: 'Roboto sans-serif'
                    }}>Connect with Experts.</h3>
                </div>
            </div >
            <div>
                <div classNameName="container">
                    <div classNameName="row">
                        <h2 style={{
                            paddingTop: '40px',
                            fontSize: '30px',
                            textAlign: 'center',
                            marginBottom: '30px',
                            fontWeight: '600',
                            color: '#000'
                        }}>Get Paid Recommendation in India anywhere </h2>

                        <div className=" container fluid col-lg-12" style={{ border: '1px', boxShadow: '0 1px 5px 1px #333' }}>


                            <form className="row g-4 formfill">

                                <h5><b style={{fontFamily:'Roboto condensed'}}>Get an Instant expert call</b></h5>
                                
                                <div className="col-md-6">
                                    <input type="text" className="form-control" id="validationDefault01" placeholder="Full Name" required />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className="form-control" id="validationDefault02" placeholder="Mobile Number" required />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className="form-control" id="validationDefault01" placeholder="Email" required />
                                </div>
                                <div className="col-md-6">

                                    <select className="form-select" id="validationDefault04" required>
                                        <option selected disabled value="">Select Services</option>
                                        <option>Stock Cash Basic</option>
                                        <option>Stock Cash Premium</option>
                                        <option>Stock Cash TechniQ</option>
                                        <option>Stock Future Basic</option>
                                        <option>Stock Future Premium</option>
                                        <option>Stock Future TechniQ</option>
                                        <option>Stock Option Basic</option>
                                        <option>Stock Option Premium</option>
                                        <option>Stock Option TechniQ</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                                        <label className="form-check-label" for="invalidCheck2">
                                            <span>Note:- By Agreeing to the terms &amp; condition,even if you are NDNC registered customer,you are agree to receive call and sms.</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 mb-4" >
                                    <button className="btn btn-danger" type="submit" style={{ float: 'right' }}>As an expert</button>
                                </div>
                            </form>

                        </div>
                        <br /> <br /> <br />
                    </div>
                </div>
            </div >
            <section className="infoSection">
                <div className="container">
                    <div className="row">
                        <div className="HEADING_TAGE">
                            <h1>ADVISORY COMPANY</h1>
                            <div className="about_heading_line1"></div>
                        </div>
                        <p>We facilitate investments in Primary market and trading in Secondary Market in Equity Trading through its Corporate Membership of the premier exchanges of the country namely National Stock Exchange (NSE) and The Stock Exchange, Mumbai (BSE). We enable trading and investments across all asset classes. Our retail and High Networth Individual (HNI) clients benefit from our stockbroking services.</p>
                        <div className="col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-delay="0.6s">
                            <img src={img2} class="img-responsive" alt="Sebi Registered Advisory Company – ProfitAim Research" />
                        </div>
                        <div className="col-md-6 col-sm-6 wow fadeInRight animated" data-wow-delay="0.6s">
                            <h3>Favourable Business Practices Sustainable Profits</h3>
                            <div className="about_heading_line"></div>
                            <p>All our services are transparent which helped us gain faith from our clients. And all our trade recommendation are based on thorough fundamental and technical analysis of the market through expert research analyst. </p>
                            <ul className="about_unoder_list">
                                <li>
                                    <span className="about_list_order">
                                        <img width="30px" src={img3} alt="ProfitAim Research Investment Advisor" /></span>
                                    <span className="about_list_text">
                                        <h3>Mission</h3>
                                        Our Research Services in Stocks and Commodities markets is our Key to Success; by delivering highest Quality Research Services and Support to our Clients; together we can create More Money form Markets.</span>
                                </li>
                                <li>
                                    <span className="about_list_order">
                                        <img width="30px" src={img3} alt="ProfitAim Research Investment Advisor" /></span>
                                    <span className="about_list_text">
                                        <h3>Goal</h3>
                                        To become the world class corporation and industry leader in the market share.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section_service_blog">
                <div className="container">
                    <div className="row">
                        <div className="sec-title text-center">
                            <h3>OUR<span> SERVICES</span> </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="service_blog">
                            <ul>
                                <li className="wow rollIn">
                                    <div className="box_s">
                                        <div className="icon icon-a1"></div>
                                        <h2>Stock Cash Basic</h2>
                                        <div className="about_heading_line"></div>
                                        <p>Our Stock Cash Basic are offered over instant messenger service and SMS that are mediated through hi-tech software.</p>

                                    </div>
                                </li>
                                <li className="wow rollIn">
                                    <div className="box_s">
                                        <div className="icon icon-a7"></div>
                                        <h2>Stock Cash Premium</h2>
                                        <div className="about_heading_line"></div>
                                        <p>We offer Stock Cash Premium recommendation at an unmatched accuracy to help our clients earn maximum profit from the exchange.</p>

                                    </div>
                                </li>
                                <li className="wow rollIn">
                                    <div className="box_s">
                                        <div className="icon icon-a4"></div>
                                        <h2>Stock Cash TechniQ</h2>
                                        <div className="about_heading_line"></div>
                                        <p>Our Stock Cash TechniQ help our investors trade the market safely. We cover the stocks cash segments .</p>

                                    </div>
                                </li>
                                <li className="wow lightSpeedIn">
                                    <div className="box_s">
                                        <div className="icon icon-a3"></div>
                                        <h2>Stock Future Basic</h2>
                                        <div className="about_heading_line"></div>
                                        <p>Our recommendation on the stock exchange are completely customized customer service where our customers would get calls in equity.</p>

                                    </div>
                                </li>
                                <li className="wow lightSpeedIn">
                                    <div className="box_s">
                                        <div className="icon icon-a9"></div>
                                        <h2> Stock Future Premium</h2>
                                        <div className="about_heading_line"></div>
                                        <p>Our Stock Future Premium Calls are generated by certified technical experts with bigger targets and minimum risk which assured accuracy.</p>

                                    </div>
                                </li>
                                <li class="wow bounceInRight">
                                    <div class="box_s">
                                        <div class="icon icon-a6"></div>
                                        <h2>Stock Future TechniQ</h2>
                                        <div class="about_heading_line"></div>
                                        <p>We offer focused and personalized service based on the investment plan and trading patterns in the equity market.</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <FooterHeader/>
        </>

    );
}

export default Home;