import './App.css';
import About from './Componants/About/About';
import Blog from './Componants/Blog/Blog';
import Header from './Componants/Header/Header';
import Home from './Componants/Home/Home';
import Termscondition from './Componants/Terms&Condition/TermsCondition';
import {  Routes  } from 'react-router-dom';
import { Route } from 'react-router-dom';

function App() {
  return (
    <>
    
   
        <Routes>
          <Route path="/" element={<Home />}/>
          
          <Route path="header" element={<Header />}/>
          <Route path="about" element={<About />}/>
          <Route path="termscondition" element={<Termscondition />}/>
          <Route path="blog" element={<Blog />}/>
         
          
        </Routes>
      
    </>
  );
}

export default App;
