import React from "react";
import './Header.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

function Header() {

  const scrollToSection = () => {
    const targetSection = document.getElementById('targetSection');
    
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar expand="lg" className="ste" id="myHeader">
        <Container className="container">
          <Navbar.Brand className="NavbarBrand" as={Link} to="/">GreenMountainsResearch</Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end">
            <Offcanvas.Header closeButton />
            <Offcanvas.Body>
              <Nav className="ul justify-content-end flex-grow-1 pe-3">
                <Nav.Link className="li" as={Link} to="/">Home</Nav.Link>
                <Nav.Link className="li" onClick={scrollToSection}>Services</Nav.Link>
                <Nav.Link className="li" as={Link} to="/termscondition">Terms & Condition</Nav.Link>
                <Nav.Link className="li" as={Link} to="/about">About Us</Nav.Link>
                <Nav.Link className="li" as={Link} to="/blog">Blog</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
export default Header;