import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import Header from "../Header/Header";
import FooterHeader from "../FooterHeader/FooterHeader";
import "./About.css";


function About() {
    return (
        <>
        <Header />
            <section className="section_service_blog">
                <div className="container">
                    <div className="row">
                        <div className="sec-title text-center">
                            <h3>About<span> Us</span> </h3>
                        </div>
                    </div>
                    <div className="row wow bounceInDown">
                        <div className="col-md-12">

                            <h2 className="who_we_are">Who We Are? </h2>

                            <p>GreenMountainsResearch Research is SEBI Registered investment advisory company. As an all-around oversaw and acclaimed fast services provider in the advisory business, we are glad for the trusted clientele who earned incredible achievement in the Stock Market by the investment plans represented by our specialists.</p>

                            <p>We also help our customers with prompt stock market recommendation in bullion, metals, option future recommendation, equity intraday recommendation, intraday call put recommendation, positional trading recommendation, btst stock recommendation, intraday strategy in derivatives, commodities and agricultural products traded in NSE, BSE, NCDEX and MCX segments.</p>

                            <span><b>What Makes GreenMountainsResearch Research Different From Other Trading recommendation Provider</b></span>
                            <p>GreenMountainsResearch’s professional team makes our company more experienced, handy and quantitative to make coveted benefit from money invested in Stock Market. Our team gives you recommendations on appropriate segment according to your danger bearing limit. Each individual's motivation to contribute and exchange all alone basics is one of a kind. Regardless of the aptitude level, we give the complete investment arrangements over speculation items for traders and investors.</p>

                            <p>GreenMountainsResearch Research is not just an Stock Advisory firm, we are a Research Advisory Firm where we perform technical as well as fundamental research to provide highly accurate share market intraday trading recommendation.</p>



                            <span><b>GreenMountainsResearch Research Pune History and Expertise</b></span>

                            <p>We have a skilled and knowledgeable technical analyst team who provide precise Call Put Target based recommendation by analyzing Stock Market trading trends.</p>
                            <p>We utilize the key and in addition technical analysis furthermore trade the effect of world stock market on Indian Market. We likewise deal with the most recent News on different occasions amid the examination. <span style={{ fontWeight: "700", color: "#357ed8", cursor: "pointer" }}>We are a one stop hotspot for the investors</span> around the globe and take into account their financial investment needs with low risk high return in equity, derivatives and MCX trading.</p>
                            <p>We give calls to our customers through SMS and Telephonically. Our SMS sevice is an extremely proficient framework guaranteeing the moment conveyance of Message with no loss of time. In this way, the customers get satisfactory time to go into the trade and get the most maximum benefit. We offer enhanced scope of services according to the investments of an investor, trader and broker.</p>

                            <p>Our <strong>100% TECHNICAL AND FUNDAMENTAL RESEARCH BASED CALLS </strong>are based on technical analysis of the market with allocations made at stock market basics and its danger reward proportion. Every Executive of our organization is dependable, straightforward and focused on the pleasure of our clients. We don't offer verbal suggestions and send exchange signs through SMS. We additionally associate with our clients through Telephonically visit and delivery person services with call at whatever point they desire.</p>

                            <span><b>Stock Market Success with Our best Technical Analyst Team</b></span>
                            <p>Every person associated with our organization is always straightforward which helped us gain confidence from our customers. And all our trading recommendation are outcome of thorough fundamental and technical analysis of the Stock Market through <strong>Technical Experts</strong>. Most importantly, we are completely supportive to our clients &amp; help them make the best out of the stock market.</p>



                        </div>
                    </div>

                    <div className="col-md-7 pr-md-65">
                        <div className="sec-title">
                            <h3>Our Expertise</h3>
                            <h4>WHO WE ARE &amp; WHAT WE DO...?</h4>
                        </div>
                        <p className="about-desc">We are purely a research house offering wide range of financial solutions to day traders, investors and individuals seeking for financial support while they can focus on their core competencies or business.</p>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Financial Managment and Consulting</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Advice and Assistance Investing</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Comprehensive Support for Your Portfolio</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Personalized service</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Appropriate Risk assessment</h4>
                        </div>
                        <div className="col-md-6">
                            <h4><span ><i className="fa fa-check"></i></span> Client Profiling</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Swift real time assistance</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Domestic &amp; Global market updates</h4>
                        </div>
                        <div className="col-md-12">
                            <h4><span ><i className="fa fa-check"></i></span> Weekly reports</h4>
                        </div>
                    </div>
                </div>

            </section>

            <FooterHeader />
        </>
    )
}

export default About;